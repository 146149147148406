import React from 'react';
import './nav.css';

class Nav extends React.Component {

  state = {

  }

  render() {
    return (
      <div className='nav-div'>
        <div className='nav-bar'>
          <ul>
            <li className='nav-link'><a href="https://butterimage.com">home</a></li>
            <li className='nav-link nav-active'>sound</li>
          </ul>
        </div>
        {/* <a className="nav-link" href="https://sight.butterimage.com">Sight</a>| */}
        {/* <Link className="nav-link" to="/taste">Taste</Link>| */}

      </div>
    );
  }
}

export default Nav
