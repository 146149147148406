import React from "react";
import axios from "axios";
        
import AudioPlayer from "../components/audio-player"
import Card from "../components/card";
import './inventory.css'; 

class Inventory extends React.Component{
  state={
    inventory: [],
    player: {
      currentArtAlbum: '...',
      nowPlaying: '...',
      album: '...',
      artist: '...',
      songName: '...'
    }
  }
  
  componentDidMount() {
    axios.get('https://six.butterimage.com/get_butter-sound.php')
      .then(response=>{
        this.setState({inventory: response.data})
      });
  }

  handleClick = (obj) => {
    // Changing state
    this.setState({ currentArtAlbum: obj.urlAssetArtCover });
    this.setState({ songName: obj.track_txt});
    this.setState({ nowPlaying: obj.urlAssetContent });
    this.setState({ album: obj.album_txt });
    this.setState({ artist: obj.artist_txt });
  }

  render () {
    return (
      <div>
        <AudioPlayer albumArt={this.state.currentArtAlbum} nowPlaying={this.state.nowPlaying} album={this.state.album} artist={this.state.artist} songName={this.state.songName}/>
        <div className="container">
          {this.state.inventory.map((object)=>{
              return <div className="card" onClick={()=>this.handleClick(object)}>
                  <Card albumArt={object.urlAssetArtCover} 
                  nowPlaying={object.track_txt} 
                  album={object.album_txt} 
                  artist={object.artist_txt}/>
                  </div>
          })}
        </div>
      </div>
    )
  }
}
export default Inventory
