import React, { Component } from 'react';
// import styled, { css } from "styled-components";

import './card.css';


class Card extends Component {

  render(){
    return (
      <div>
        <div className="title">Track: {this.props.nowPlaying}</div>
        <div className="content"> 
          <div><img src={"https://six.butterimage.com/sense/sound/artwork/"+this.props.albumArt} height="75px" alt="Current album art"/></div>
          <div>Album: {this.props.album}</div>
          <div>Artist: {this.props.artist}</div>
        </div>
      </div>
    )
  }
}

export default Card;
