import { Component } from "react";
import './App.css';
import spread from './assets/butter_spread_long.png';

import ReactGA from 'react-ga4';
import Inventory from './base-elements/inventory';

class App extends Component {
  initReactGA = () => {
    ReactGA.initialize('G-9L4WNFRVG9');
    ReactGA.send('pageview');
  };
  componentDidMount(){
    this.initReactGA();
  }
  render (){
    return(
      <div className="App">
        <h1>. hear . sound . butter .</h1>
        <p>. immerse yourself in better sound than you could ever imagine .</p>
        <div className="butter-spread" style={{backgroundImage: `url(${spread})` }}></div>
        <Inventory></Inventory>
      </div>
    );
  }
}

export default App;
