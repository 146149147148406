import React, { Component } from 'react'
import ReactAudioPlayer from 'react-audio-player';

class AudioPlayer extends Component {
  render(){
    return (
      <div>
        <img src={"https://six.butterimage.com/sense/sound/artwork/"+this.props.albumArt} height="75px" alt="Current album art"/>
        <div>TRACK:{this.props.songName} ALBUM:{this.props.album} ARTIST:{this.props.artist}</div>

        <ReactAudioPlayer
          src={"https://six.butterimage.com/sense/sound/"+this.props.nowPlaying}
          autoPlay
          controls
          loop
        />
      </div>
    )
  }
}
 
export default AudioPlayer