import React from 'react';
import spread from '../assets/butter_spread_long.png';

class Footer extends React.Component {
  render() {
    return (
      <div className="footer-class">
        <div className="butter-spread" style={{backgroundImage: `url(${spread})` }}></div>
        <table className="center-content">
          <tr>
            <td>
            <subp>Hear the world butter.</subp><br/>
            </td>
            <td>
            <subp>est 2022</subp><br/>
            </td>
          </tr>
          <tr>
            <td>
            <subp>News</subp><br/>
            </td>
            <td>
            <subp>Misc</subp><br/>
            </td>
          </tr>
        </table>
      </div>
    );
  }
}

export default Footer
